.wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 80vh;
}

.spinner {
   width: 88px;
   height: 88px;
   border-radius: 50%;
   border: 6px solid;
   border-color: #E7E9E9;
   border-right-color: #00a8e1;
   animation: spinner-d3wgkg 1.2s infinite linear;
}

@keyframes spinner-d3wgkg {
   to {
      transform: rotate(1turn);
   }
}
